import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";

import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

import rotateImage from "./images/rotate.jpg";
import ndviImage from "./images/ndvi.png";
import landbankImage from "./images/landbank.jpg";
import weatherImage from "./images/weather.jpeg";
import reportsImage from "./images/reports.jpg";
import adminImage from "./images/admin.jpg";
import transportControlImage from "./images/transport-control.jpg";

const useStyles = makeStyles({
  card: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
});

function ServiceCard(props) {
  const { title, text, image, link, disabled } = props;
  const classes = useStyles();

  return (
    <Card
      className={classes.card}
      style={{
        margin: 6,
        width: 400,
        filter: disabled ? "grayscale(100%)" : "",
        opacity: disabled ? "0.7" : "1",
      }}
      onClick={() => {}}
    >
      <CardActionArea>
        <CardMedia className={classes.media} image={image} title={title} />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {title}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {text}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

const Modules = () => {
  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      style={{ padding: 20 }}
    >
      <div
        onClick={() => {
          window.location.href = "https://landbank.development.agro-unit.net";
        }}
      >
        <ServiceCard
          title="Земельный банк"
          text="Модуль управления земельными ресурсами."
          image={landbankImage}
          link="/landbank/index"
        />
      </div>
      <div
        onClick={() => {
          window.location.href =
            "https://transport-control.development.agro-unit.net";
        }}
      >
        <ServiceCard
          title="Транспортный контроль"
          text="Модуль мониторинга транспортный средств."
          image={transportControlImage}
          link="/transport-control/transport-tracker"
        />
      </div>
      <ServiceCard
        title="Погода"
        text="Погодная статистика."
        image={weatherImage}
      />
      <ServiceCard
        title="NDVI"
        text="Динамические спутниковые снимки."
        image={ndviImage}
      />
      <ServiceCard
        title="Севооборот"
        text="Учет севооборота."
        image={rotateImage}
      />

      <ServiceCard
        title="Отчеты"
        text="Статистика и аналитика."
        image={reportsImage}
      />

      <ServiceCard
        title="Административная панель"
        text="Управление компаниями и пользователями."
        image={adminImage}
        link="/admin/index"
      />
    </Grid>
  );
};

export default Modules;
